import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@komo-tech/core/models/ImageDataModel';
import { CSSProperties } from 'react';

import {
  BlockItemData,
  BlockItemSizeAutoBehaviour,
  BlockSize,
  BlockTypes
} from '../../types/_shared';
import { BlockItem } from '../../types/BlockItem';

export class BlockImageItem extends BlockItem<BlockImageItemData> {
  type = BlockTypes.Image;
  constructor(props?: Partial<BlockImageItem>) {
    props = props || {};
    super({
      childOptions: {
        enabled: false
      },
      sizeOptions: {
        aspectRatio: {
          enabled: true,
          locked: {
            value: (d) => d?.image?.size,
            disabled: false
          }
        },
        height: { lockedAutoBehaviour: 'Fill' },
        width: { lockedAutoBehaviour: 'Fill' }
      }
    });
    Object.assign(this, this.sanitiseProps(props));
    this.setDefaults();
  }

  getData(): BlockImageItemData {
    return new BlockImageItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockImageItemData>) {
    return new BlockImageItemData({
      ...this.getData(),
      ...change
    });
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    const images: ImageDataModel[] = [];
    const data = this.getData();
    if (data.image?.url) {
      images.push(data.image);
    }

    return () => Promise.resolve(images);
  }

  getRenderData(scale: number) {
    const data = this.getData();

    const style: CSSProperties = {
      ...this.getBaseStyle(scale),
      objectFit: 'cover'
    };

    return {
      image: data.image,
      dataAttributes: this.getElementProps('Item').dataAttributes,
      style
    };
  }

  static new(image: ImageDataModel) {
    return new BlockImageItem({
      jsonData: JSON.stringify(
        new BlockImageItemData({
          image,
          size: new BlockSize({
            aspectRatio: image?.size
          })
        })
      )
    });
  }
}

export class BlockImageItemData extends BlockItemData {
  image: ImageDataModel;
  constructor(props?: Partial<BlockImageItemData>) {
    super();
    Object.assign(this, this.mergeDefaults(props));
    this.setDefaults();
  }

  protected setDefaults() {
    super.setDefaults();
    this.image = new ImageDataModel(this.image);
    this.hAlign = undefined;
    this.vAlign = undefined;
  }

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Fill',
      width: 'Fill'
    };
  }
}
